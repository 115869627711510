.WaitingPlayers {
  padding: 20px 26px 26px 26px;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.WaitingPlayers div {
  color: #000;
  margin: 5px 3px;
  background-color: #c8dbff;
  width: calc(50% - 6px);
  font-size: 17px;
  border: none;
  display: flex;
  height: 36px;
  border-radius: 18px;
  padding: 5px 0 5px 10px;
  box-sizing: border-box;
}

.WaitingPlayers div:last-of-type {
  padding: 0;
}
