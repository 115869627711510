.VotableAnswers {
  padding: 0 26px;
  margin-bottom: 30px;
}

.answer {
  width: 100%;
  font-size: 17px;
  resize: none;
  box-sizing: border-box;
  min-height: 120px;
  border: 2px solid #000;
  border-radius: 4px;
  padding: 16px;
}

.answer + .answer {
  margin-top: 16px;
}

.answer label {
  width: 100%;
  display: block;
}

/* Hide the default radio button appearance */
.VotableAnswers input[type='radio'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.VotableAnswers label {
  cursor: pointer;
  position: relative;
}

.VotableAnswers label > p {
  padding-left: 5px;
  margin: 10px 0;
}

.VotableAnswers label > div {
  position: absolute;
  display: inline-block;
  margin-left: 10px;
}

/* Custom unchecked state */
.VotableAnswers label:before {
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgNzQgNzQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpteGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MjsiPjxnPjxnIHRyYW5zZm9ybT0ibWF0cml4KDEuMDA1NzIsMCwwLDEuMDA1NzIsLTg1NjQuNDQsLTM4MjcuOTQpIj48Y2lyY2xlIGN4PSI4NTUyLjIzIiBjeT0iMzg0Mi42NyIgcj0iMjkuMzIiIHN0eWxlPSJmaWxsOndoaXRlOyIvPjxwYXRoIGQ9Ik04NTUyLjIzLDM4MDkuMzdDODU3MC42MSwzODA5LjM3IDg1ODUuNTMsMzgyNC4yOSA4NTg1LjUzLDM4NDIuNjdDODU4NS41MywzODYxLjA0IDg1NzAuNjEsMzg3NS45NiA4NTUyLjIzLDM4NzUuOTZDODUzMy44NSwzODc1Ljk2IDg1MTguOTMsMzg2MS4wNCA4NTE4LjkzLDM4NDIuNjdDODUxOC45MywzODI0LjI5IDg1MzMuODUsMzgwOS4zNyA4NTUyLjIzLDM4MDkuMzdMODU1Mi4yMywzODEzLjM1Qzg1MzYuMDUsMzgxMy4zNSA4NTIyLjkxLDM4MjYuNDggODUyMi45MSwzODQyLjY3Qzg1MjIuOTEsMzg1OC44NSA4NTM2LjA1LDM4NzEuOTkgODU1Mi4yMywzODcxLjk5Qzg1NjguNDEsMzg3MS45OSA4NTgxLjU1LDM4NTguODUgODU4MS41NSwzODQyLjY3Qzg1ODEuNTUsMzgyNi40OCA4NTY4LjQxLDM4MTMuMzUgODU1Mi4yMywzODEzLjM1WiIvPjwvZz48ZyBpZD0iY2hlY2stbWFyay1zdmdyZXBvLWNvbS5zdmciPjwvZz48L2c+PC9zdmc+');
  display: inline-block;
  width: 46px;
  height: 46px;
}

/* Custom checked state */
.VotableAnswers input[type='radio']:checked + label:before {
  content: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjMwIDIzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWw6c3BhY2U9InByZXNlcnZlIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjIiPjxjaXJjbGUgY3g9IjExNC42ODIiIGN5PSIxMTQuNjgyIiByPSI5Mi4xNDciIHN0eWxlPSJmaWxsOiNiOWY3YmMiLz48cGF0aCBkPSJNMTE0LjY4MiAxMC4wMzVjNTcuNzU3IDAgMTA0LjY0OCA0Ni44OTEgMTA0LjY0OCAxMDQuNjQ3IDAgNTcuNzU3LTQ2Ljg5MSAxMDQuNjQ4LTEwNC42NDggMTA0LjY0OC01Ny43NTYgMC0xMDQuNjQ3LTQ2Ljg5MS0xMDQuNjQ3LTEwNC42NDggMC01Ny43NTYgNDYuODkxLTEwNC42NDcgMTA0LjY0Ny0xMDQuNjQ3Wm0wIDEyLjVjLTUwLjg1NyAwLTkyLjE0NyA0MS4yOS05Mi4xNDcgOTIuMTQ3IDAgNTAuODU4IDQxLjI5IDkyLjE0OCA5Mi4xNDcgOTIuMTQ4IDUwLjg1OCAwIDkyLjE0OC00MS4yOSA5Mi4xNDgtOTIuMTQ4IDAtNTAuODU3LTQxLjI5LTkyLjE0Ny05Mi4xNDgtOTIuMTQ3WiIvPjxwYXRoIGQ9Im01OS43MjMgMTE3LjE4NSAzOS4yNjkgMzguMzAxIDcwLjY0OS02OC41NjktMTMuNDg5LTEzLjAzOS01Ny4xNiA1NS42NDctMjYuMTk0LTI1LjcyOC0xMy4wNzUgMTMuMzg4Ii8+PC9zdmc+');
  display: inline-block;
  width: 46px;
  height: 46px;
}
