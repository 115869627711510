.Label {
  color: #000;
  text-transform: uppercase;
  margin: 26px 26px 0 26px;
}

.Label p {
  font-size: 18px;
  margin: 0 0 10px 0;
  font-weight: 600;
}

.Label[data-underline='true'] {
  border-bottom: 2px solid #000;
}
