.Voting {
  padding: 0;
  border: 2px solid #000;
}

.Voting section + section {
  border-top: 1px solid #aea392;
}

.buttons {
  border-top: 2px solid #000;
  display: flex;
  margin-top: 20px;
  padding: 26px;
}

.buttons button + button {
  margin-left: 10px;
}

.question {
  padding: 0 26px;
  margin: 0 0 40px 0;
  font-size: 18px;
}

.LineWrapper {
  padding: 38px 26px;
  border-top: 2px solid #000;
}
