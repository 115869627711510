.Gameplay {
  padding: 0;
  border: 2px solid #000;
}

.Question {
  margin: 0 26px 30px 26px;
}

.LineWrapper {
  padding: 38px 26px;
  border-top: 2px solid #000;
}
