.SingleInputForm {
}

.SingleInputForm .top {
  display: flex;
  flex-direction: column;
  padding: 0 26px;
}

.SingleInputForm label {
  margin: 0 0 10px 0;
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
  font-size: 18px;
}

.SingleInputForm input {
  height: 48px;
  border-radius: 4px;
  font-size: 18px;
  padding: 0 20px;
  box-sizing: border-box;
  border: 2px solid #000;
}
.SingleInputForm .bot {
  margin-top: 30px;
  padding: 26px;
  border-top: 2px solid #000;
}

.SingleInputForm button {
  background-color: #ffdfaf;
  /* background-color: #ffd700;  */
  height: 48px;
  border-radius: 4px;
  font-size: 18px;
  padding: 0 20px;
  box-sizing: border-box;
  border: 2px solid #000;
  width: 100%;
}

.SingleInputForm button:disabled {
  background-color: #e6dad8;
  cursor: not-allowed;
  color: #000;
}
