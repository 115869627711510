.ProgressLine {
  height: 20px;
  display: flex;
  width: 100%;
  border: 2px solid #000;
  border-radius: 4px;
}

.ProgressLine div[data-done='true'] {
  flex: 1;
  background-color: #b9f7bc;
  border-right: 2px solid #000;
}

.ProgressLine div[data-done='false'] {
  flex: 1;
  background-color: #fffc;
}
