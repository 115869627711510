.Footer {
  display: flex;
  justify-content: center;
  padding: 30px;
  border-top: 1px solid #e1e1e1;
}

.Footer button {
  border-radius: 4px;
  font-weight: 400;
  background-color: #ffff;
  border: none;
  box-sizing: border-box;
  font-size: 18px;
  padding: 0 20px;
}
