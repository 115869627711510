.TopVotes {
  padding: 0;
  border: 2px solid #000;
}

.topAnswerer {
  display: flex;
  flex-wrap: wrap;
}

.topAnswerer,
.mostStarredAnswers {
  padding: 0 26px;
  margin-bottom: 30px;
}

.TopVotes > div + div {
  margin-top: 20px;
}

.buttons {
  display: flex;
  padding: 26px;
  border-top: 2px solid #000;
}

.buttons button + button {
  margin-left: 10px;
}

.answer {
  width: 100%;
  font-size: 17px;
  resize: none;
  box-sizing: border-box;
  min-height: 120px;
  border: 2px solid #000;
  border-radius: 4px;
  padding: 16px;
}

.answer + .answer {
  margin-top: 16px;
}

.answer > .q {
  margin: 0 0 20px 5px;
}

.answer > .a {
  margin: 20px 0 5px 5px;
}
