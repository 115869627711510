.TextAreaForm {
  display: flex;
  flex-direction: column;
}

.TextAreaForm label {
  font-weight: 600;
}

.TextAreaForm textarea {
  width: 100%;
  font-size: 17px;
  resize: none;
  box-sizing: border-box;
  min-height: 120px;
  border: 2px solid #000;
  border-radius: 4px;
  padding: 14px 20px;
}

.TextAreaWrap {
  padding: 0 26px 26px 26px;
}

.DynamicButtonWrapper {
  border-top: 2px solid #000;
  display: flex;
  margin-top: 30px;
  padding: 26px;
}

.DynamicButtonWrapper button:first-of-type {
  margin-right: 12px;
  max-width: 30%;
}

.DynamicButtonWrapper button:first-of-type:disabled {
  display: none;
}
