.Thanks {
  border: 2px solid #000;
  padding: 0;
}

.testers {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 20px 26px 26px;
}

.wrapper {
  border-top: 2px solid #000;
  margin-top: 30px;
  padding: 26px;
}
