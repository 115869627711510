.Player {
  color: #000;
  margin: 5px 3px;
  background-color: #c8dbff;
  width: calc(50% - 6px);
  font-size: 17px;
  border: none;
  display: flex;
  justify-content: space-between;
  height: 36px;
  border-radius: 18px;
  padding: 5px 15px 5px 10px;
  box-sizing: border-box;
  align-items: center;
}

.Player p {
  margin: 0;
  max-width: 90%;
  overflow: hidden;
  text-transform: capitalize;
}

.Player[data-small='true'] {
  height: 28px;
}

.Player[data-shiny='true'] {
  background-image: url('../../images/effects/sparkles.webp'),
    url('../../images/effects/holo.webp'),
    linear-gradient(
      300deg,
      #ff008450 15%,
      #fca40040 30%,
      #ffff0030 40%,
      rgba(0, 255, 55, 0.67) 60%,
      rgba(0, 255, 187, 0.69) 70%,
      rgba(96, 250, 76, 0.77) 85%
    );
  background-position: 50% 50%;
  background-size: 160%;
  background-blend-mode: overlay;
  z-index: 2;
  filter: brightness(1) contrast(1);
  transition: all 0.33s ease;
  opacity: 0.95;
  text-shadow: 2px 2px 6px #ffffff;
}

.Player[data-small='true'] p {
  font-size: 13px;
}

.num {
  font-weight: 600;
}
