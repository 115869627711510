.Results {
  padding: 0;
  border: 2px solid #000;
}

.Results section + section {
  margin-top: 40px;
}

.question {
  padding: 0 26px;
  margin: 20px 0 30px 0;
}

.question > p {
  margin: 0 0 0 10px;
}

.answers {
  display: flex;
  flex-direction: column;
  padding: 0 26px;
  margin-top: 20px;
}

.wrapper {
  display: flex;
  padding: 26px;
  border-top: 2px solid #000;
  margin-top: 20px;
}
