.Button {
  width: 100%;
  color: #1e1e1e;
  border-radius: 4px;
  font-weight: 400;
  background-color: #ffdfaf;
  border: 2px solid #000;
  box-sizing: border-box;
  font-size: 18px;
  height: 48px;
  padding: 0 20px;
}

.Button:disabled {
  background-color: #e6dad8;
}

form + .Button {
  border-top: 0;
}
